import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Apple’s New M1 Chip - Blurring The Future for PCs",
  "date": "2020-11-14",
  "slug": "blog/apples-new-m1-chip-blurring-the-future-for-pcs",
  "featuredImage": "./images/apple.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Apple is back to slamming the PC community with the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=tEvXVJHTQAk"
      }}>{`“PC Guy” video ads`}</a>{`, this time taking aim at power efficiency achievements provided by their new M1 chip.`}</p>
    <p>{`The M1 is a system-on-chip, meaning all core operational components are embedded on the same chip. This extends power efficiency and embraces a sort of utopian view on the future of hardware.  The M1 is based on a 5-nanometer architecture, outpacing current mainstream PC part fabrication processes, which currently stand at 14nm (Intel), 7nm (AMD), and 8nm (Nvidia). The smaller, the better, since you can cram more transistors in the same amount of space and use less power for processing grunt. The M1 packs 16 billion transistors. Cramming this many transistors is quite impressive inside something like a laptop. For comparison, a modern laptop CPU from AMD fabricated from a 7nm process with integrated GPU, the Ryzen 4000, packs 9.8 billion transistors. Apple’s first iteration of a laptop 5nm SOC using ARM seems like a step in the right direction, especially for more mobile focused computing. Testing will tell if claimed efficiency numbers can be trusted. `}</p>
    <p>{`The M1 chip has four high-performance cores featuring 192KB of cache, 128KB of data cache, and shared 12MB L2 cache. In short, Apple is ahead here. They’ve embraced the ARM architecture over the traditional x86. While this comes with software challenges to run x86 programs, they’ve bet on a promising future with ARM architecture. `}</p>
    <p>{`What does this mean for the future of PCs? I ponder the thought of a desktop grade system-on-chip unit. My immediate thought is that reduced modularity would limit upgrade paths. If you want a new GPU, you’d have to buy a whole new system-on-chip. The new system-on-chip would come with new optimizations for ideal component harmony, though. It would also be likely that software compatibility could be optimized easier by developers since they know the full makeup of the system.`}</p>
    <p>{`With Apple shipping new M1 laptops to the masses, hopefully we can gain some insight of the pros and cons over the next few months. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      